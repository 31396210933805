import React, { useMemo, useEffect } from 'react';
import { useQuery } from 'react-query';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SearchBar from '../../components/SearchBar';
import { Product, APICategory } from '../../types/types';
import { FaSpinner } from 'react-icons/fa';
import Link from 'next/link';
import Image from 'next/image';
import axiosInstance from '../../utils/axiosConfig';
import Head from 'next/head';
import Script from 'next/script';
import ProductGroup from '../../components/ProductGroup';

interface AggregatedProductsResponse {
  popularProducts: Product[];
  recommendedProducts: Product[];
  debugCandidates?: unknown[];
}

const computeMinPrice = (product: Product): number | null => {
  const prices: number[] = [];
  if (product.price !== null && product.price > 0 && Number.isFinite(product.price)) {
    prices.push(product.price);
  }
  if (product.skus && product.skus.length > 0) {
    product.skus.forEach(sku => {
      if (sku.price !== null && sku.price > 0 && Number.isFinite(sku.price)) {
        prices.push(sku.price);
      }
    });
  }
  return prices.length > 0 ? Math.min(...prices) : null;
};

const HomePage: React.FC = () => {
  const subcategories = useMemo(() => [
    {
      name: "BASE",
      categories: [
        "化粧下地",
        "BBクリーム",
        "ファンデーション",
        "コンシーラー",
        "フェイスパウダー",
        "シェーディング",
        "ハイライト",
      ],
    },
    {
      name: "EYE",
      categories: [
        "アイシャドウ",
        "アイブロウ",
        "アイライナー",
        "マスカラ",
        "まつ毛美容液/下地",
      ],
    },
    {
      name: "LIP & CHEEK",
      categories: ["チーク", "リップ", "リップ下地/リップケア"],
    },
    {
      name: "SKINCARE",
      categories: ["化粧水", "美容液", "乳液/クリーム/UVケア", "洗顔/クレンジング", "パック/マスク"],
    },
    {
      name: "HAIR",
      categories: ["ヘアケア", "シャンプー", "トリートメント"],
    },
    {
      name: "その他",
      categories: ["香水", "ネイル", "ボディケア" ,"ツール"],
    },
  ], []);

  const { data: aggregatedData, isLoading: isAggregatedLoading, error: aggregatedError } = useQuery<AggregatedProductsResponse, Error>(
    'aggregatedProducts',
    async () => {
      console.log("Requesting aggregated-products endpoint...");
      const response = await axiosInstance.get('products/aggregated-products/?period=total');
      console.log("Aggregated products response:", response.data);
      return response.data;
    },
    {
      staleTime: 5 * 60 * 1000,
      retry: 2,
    }
  );

  const { data: categoriesData, isLoading: isCategoriesLoading, error: categoriesError } = useQuery<APICategory[], Error>(
    'categories',
    async () => {
      try {
        const response = await axiosInstance.get('/categories/');
        return response.data;
      } catch (error) {
        console.error("Error in categories query:", error);
        throw error;
      }
    },
    {
      staleTime: 5 * 60 * 1000,
      retry: 2,
      onError: (error) => {
        console.error("Error fetching categories:", error);
      },
    }
  );

  const error = aggregatedError || categoriesError;

  const categorizedCategories = useMemo(() => {
    if (!categoriesData) return [];

    return subcategories.map((subcategory) => {
      const items = categoriesData.filter((category) =>
        subcategory.categories.includes(category.name)
      );

      return {
        subcategoryName: subcategory.name,
        categories: items,
      };
    });
  }, [categoriesData, subcategories]);

  useEffect(() => {
    if (aggregatedData) {
      console.log("Aggregated Data:", aggregatedData);
    }
  }, [aggregatedData]);

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col">
      {/* SEO関連設定 */}
      <Head>
        <title>Kosumiru - コスメ最安値比較サイト</title>
        <meta name="description" content="Kosumiruで欲しいコスメの最安値を比較し、賢くお買い物をしましょう！" />
        <link rel="canonical" href="https://kosumiru.com" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Kosumiru",
              "description": "コスメの最安値比較サイトです。",
              "url": "https://kosumiru.com",
            }),
          }}
        />
      </Head>

      {/* Googleアナリティクス */}
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-ZVQFPDXFXW"
        strategy="lazyOnload"
      />
      <Script id="google-analytics" strategy="lazyOnload">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-ZVQFPDXFXW', {
            page_path: window.location.pathname,
          });
        `}
      </Script>
      <Header />
      <div className="flex-grow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-4">
            <h1 className="text-lg font-semibold text-gray-700 text-center">
              欲しいコスメの最安値を見つけよう
            </h1>
            <div className="mt-4">
              <SearchBar />
            </div>
          </div>

          {isAggregatedLoading || isCategoriesLoading ? (
            <div className="flex justify-center items-center">
              <FaSpinner className="animate-spin text-3xl text-gray-500" />
            </div>
          ) : error ? ( 
            <div className="text-red-500 text-center">{error.message}</div>
          ) : (
            <>
              {/* 人気商品の最安値セクション */}
              <section className="mt-8">
                <h2 className="text-lg font-semibold text-gray-700 mb-4">人気商品の最安値</h2>
                <div className="overflow-x-auto whitespace-nowrap space-x-2 flex">
                {aggregatedData?.popularProducts.map((product, index) => {
                  const computedPrice = computeMinPrice(product);
                  const finalPrice =
                    product.parent_product && product.parent_product.lowest_price != null
                      ? product.parent_product.lowest_price
                      : computedPrice;
                  return (
                    <ProductGroup
                      key={product.id}
                      primaryProduct={product}
                      groupMinPrice={finalPrice !== Infinity ? finalPrice : null}
                      isLCP={index === 0}
                    />
                  );
                })}
                </div>
              </section>
              {/* 今日のおすすめセクション */}
              <section className="mt-8">
                <h2 className="text-lg font-semibold text-gray-700 mb-4">今日のおすすめ</h2>
                <div className="overflow-x-auto whitespace-nowrap space-x-2 flex">
                  {aggregatedData?.recommendedProducts.map((product) => {
                    const computedPrice = computeMinPrice(product);
                    const finalPrice =
                      product.parent_product && product.parent_product.lowest_price != null
                        ? product.parent_product.lowest_price
                        : computedPrice;
                    return (
                      <ProductGroup
                        key={product.id}
                        primaryProduct={product}
                        groupMinPrice={finalPrice !== Infinity ? finalPrice : null}
                      />
                    );
                  })}
                </div>
              </section>

              {/* カテゴリーセクション */}
              <section className="mt-8">
                <h2 className="text-lg font-semibold text-gray-700 mb-4">カテゴリ</h2>
                {categorizedCategories.length === 0 ? (
                  <div className="text-gray-500 text-center">カテゴリがありません。</div>
                ) : (
                  categorizedCategories.map((group) => (
                    <div key={group.subcategoryName} className="mb-6">
                      <h3 className="text-md font-medium text-gray-600 mb-2">{group.subcategoryName}</h3>
                      {group.categories.length === 0 ? (
                        <div className="text-gray-400">このサブカテゴリに該当するカテゴリがありません。</div>
                      ) : (
                        <div className="flex space-x-4 overflow-x-auto pb-4">
                          {group.categories.map((category) => (
                            <div
                              key={category.id}
                              className="flex-shrink-0 w-24 flex flex-col items-center text-center"
                            >
                              <Link
                                href={`/search?category=${encodeURIComponent(category.name || '')}`}
                                passHref
                              >
                                <div className="w-16 h-16 rounded-full flex items-center justify-center bg-gray-200 overflow-hidden transition-transform transform hover:scale-105">
                                  {category.image ? (
                                    <Image
                                      src={category.image}
                                      alt={category.name}
                                      width={64}
                                      height={64}
                                      quality={85}
                                      className="w-full h-full object-cover"
                                      placeholder="blur"
                                      blurDataURL="/images/placeholder.png"
                                      sizes="64px"
                                    />
                                  ) : (
                                    <span className="text-sm text-gray-500">画像なし</span>
                                  )}
                                </div>
                              </Link>
                              <p className="text-xs mt-2">{category.name}</p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))
                )}
              </section>
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
